import { Controller } from "@hotwired/stimulus"
import * as $ from 'jquery';

export default class extends Controller {
    connect(): void {
        $(this.element).find('form').each((index, form) => {
            $(form).on('submit', (e) => {
                e.preventDefault();
                this.submit(form);
            })
        });
    }

    disconnect(): void {
        $(this.element).find('form').each((index, form) => {
            $(form).off('submit')
        });
    }

    reconnect(): void {
        this.disconnect();
        this.connect();
    }

    submit(form: Element): void {
        console.log('Submitting', form);

        const $form = $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: (data) => {
                $(this.element).html(data);

                window.history.pushState({}, '', window.location.href);

                this.reconnect();
            },
            error: (jqXHR, status, error) => {
                console.log(status, error);
            }
        });
    }
}

import { Controller } from "@hotwired/stimulus"
import * as $ from 'jquery';

export default class extends Controller {
    connect(): void {
        const $originalImage = $(this.element);
        const $wrappedImage = this.transform($originalImage);

        $originalImage.replaceWith($wrappedImage);
    }

    disconnect(): void { }

    private transform($originalImage: JQuery<Element>): JQuery<Element> {
        const imagePath = $originalImage.attr('src');
        const imageClasses = $originalImage.attr('class');

        const $controllerNode = $('<div>', { class: imageClasses, 'data-controller': 'lightbox' });
        const $imageLink      = $('<a>',   { href: imagePath });
        const $image          = $('<img>', { src: imagePath });

        return $controllerNode.append(
            $imageLink.append(
                $image
            )
        );
    }
}

import { Controller } from '@hotwired/stimulus'
import * as $ from 'jquery';

export default class extends Controller {
    select: any;
    focus: boolean;
    ignoreToggle: boolean;

    connect() {
        this.select = ($(this.element) as any).selectize({
            allowEmptyOption: true,
            openOnFocus: false,
            copyClassesToDropdown: false,
            onChange: () => {
                this.focus = false;
                this.select.close();
            },
            onBlur: () => {
                this.focus = false;
                this.ignoreToggle = true;

                setTimeout(() => this.ignoreToggle = false, 250);
            },
            onFocus: function() {
                const input = 'selectize-input input',
                      wrapper = 'selectize-input';
                $('.' + input).attr('readonly', 'true');
                $('.' + input + ', .' + wrapper).css('cursor', 'pointer');
            },
        })[0].selectize;

        this.focus = false;

        // monkey patch selectize event handling, see: https://github.com/selectize/selectize.js/issues/1261
        // allows us to toggle the open state when clicking
        this.select.$control.unbind();
        this.select.$control.find('input').off('click', '**');
        this.select.$control.click(() => this.toggleFocus());

        this.dispatch('onShow');
    }

    toggleFocus() {
        if(this.ignoreToggle) return;

        if (this.focus) {
            this.focus = false;
            this.select.close();
        } else {
            this.focus = true;
            this.select.open();
        }
    }

    disconnect() {
        this.select.destroy();
        this.dispatch('onHide');
    }
}

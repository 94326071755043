import { Controller } from '@hotwired/stimulus'
import $ from 'jquery';

export default class extends Controller {
    static targets = ['collapsible', 'toggle'];
    static values = { visible: Boolean, overlays: Boolean, autoHide: Boolean };

    visible = false;

    initialize() {
        this.visible = !!this.visibleValue;
        this.overlays = !!this.overlaysValue;
        this.autoHide = !!this.autoHideValue;

        this.element[this.identifier] = this;
    }

    connect() {
        if (this.hasCollapsibleTarget) {
            this.collapsibleTarget.style.visibility = 'visible';
        }

        $(this.element).click(e => {
            e.stopPropagation();
        });

        if(this.hasToggleTarget) {
            $(this.toggleTarget).on('click', () => this.toggle());
        }

        if (this.visible) {
            this.show(true, true);
        } else {
            this.hide(true, true);
        }
    }

    disconnect() {
        if(this.hasToggleTarget) {
            $(this.toggleTarget).off('click');
        }
    }

    toggle() {
        if (this.visible) {
            this.hide(false, true);
        } else {
            this.show(false, true);
        }
    }

    show(immediate = false, force = false) {
        if (this.overlays) {
            $('#overlay')[0]?.overlay?.show();
        }

        if (this.autoHide || this.overlays) {
            $(document).one('click', e => {
                this.hide();
            });
        }

        this.visible = true;

        $(this.element).removeClass('-hidden');
        $(this.element).addClass('-open');

        if (this.hasToggleTarget) {
            $(this.toggleTarget).removeClass('-hidden');
            $(this.toggleTarget).addClass('-open');
        }

        if (this.hasCollapsibleTarget) {
            const $collapsible = $(this.collapsibleTarget);

            if(immediate) {
                $collapsible.show();
            } else {
                $collapsible.stop().slideDown();
            }

            $collapsible.removeClass('-hidden');
            $collapsible.addClass('-open');
        }
    }

    hide(immediate = false, force = false) {
        if (this.overlays) {
            $('#overlay')[0]?.overlay?.hide();
        }

        this.visible = false;

        $(this.element).addClass('-hidden');
        $(this.element).removeClass('-open');

        if (this.hasToggleTarget) {
            $(this.toggleTarget).addClass('-hidden');
            $(this.toggleTarget).removeClass('-open');
        }

        if (this.hasCollapsibleTarget) {
            const $collapsible = $(this.collapsibleTarget);

            if(immediate) {
                $collapsible.hide();
            } else {
                $collapsible.stop().slideUp();
            }

            $collapsible.addClass('-hidden');
            $collapsible.removeClass('-open');
        }
    }
}

import { Controller } from "@hotwired/stimulus"
import * as $ from 'jquery';

const dataIdOf = (link: Element | JQuery<Element>) => parseInt($(link).attr('data-id'));
const hrefOf = (link: Element | JQuery<Element>) => $(link).attr('href');

export default class extends Controller {
    static targets = ['link', 'node', 'container'];

    declare readonly hasLinkTarget: boolean
    declare readonly linkTarget: Element
    declare readonly linkTargets: Element[]

    declare readonly hasNodeTarget: boolean
    declare readonly nodeTarget: Element
    declare readonly nodeTargets: Element[]

    declare readonly hasContainerTarget: boolean
    declare readonly containerTarget: Element
    declare readonly containerTargets: Element[]

    currentId: number = 0;

    connect(): void {
        this.reattachLinks();

        window.addEventListener('popstate', this.popState);
    }

    disconnect(): void {
        this.detachLinks();

        window.removeEventListener('popstate', this.popState);
    }

    private popState(e: PopStateEvent) {
        window.location.href = (e.target as Window).location.href;
    }

    private detachLinks() {
        if(!this.linkTargets || !this.linkTargets.forEach) return;

        this.linkTargets.forEach(link => $(link).off('click'));
    }

    private reattachLinks() {
        if(!this.linkTargets || !this.linkTargets.forEach) return;
        this.detachLinks();

        this.linkTargets.forEach(link => $(link).on('click', (e) => {
            e.preventDefault();

            if (this.currentId !== dataIdOf(link)) {
                this.open(dataIdOf(link), hrefOf(link));

                return;
            }

            if (!$(link).attr('data-collapsible-target')) {
                return;
            }

            const isToggle = $(link).attr('data-collapsible-target')
                                    .split(' ')
                                    .includes('toggle');

            if (isToggle) {
                this.collapsibleFor(link).toggle();
            }
        }));
    }

    private open(id: number, href: string = null) {
        this.currentId = id;

        const node = this.findNodeById(id);

        if (!node) {
            return console.error('Cannot find node with id: ', id);
        }

        const $container = $(this.containerTarget);
        const url = href || hrefOf(node);

        $container.insertAfter(node);

        return $.ajax({
            dataType: 'json',
            cache: false,
            type: 'get',
            url: url,
            success: (response) => {
                $container.html(response.rendered);

                for (let locale in response.links_for_languages) {
                    if (response.links_for_languages.hasOwnProperty(locale)) {
                        let href = response.links_for_languages[locale];

                        $('.header__globe-language[data-locale=' + locale + ']').attr('href', href as string);
                    }
                }

                $(document).find("title").text(response.page_title);

                window.history.pushState({
                    section: {
                        id: id,
                        href: href,
                    }
                }, response.page_title, url);

                this.setStateById(id);
            }
        }).always(
            () => this.reattachLinks()
        );
    }

    private findNodeById(id: number): Element | null {
        return this.nodeTargets.find(node => dataIdOf(node) === id) || null;
    }

    private collapsibleFor(node: Element): any | null {
        const $closest = $(node).closest('[data-controller="collapsible"]');

        if (!$closest.length || !($closest[0] as any).collapsible) {
            return null;
        }

        return ($closest[0] as any).collapsible;
    }

    private setStateById(id: number): void {
        // Responsible for:
        //   - setting active / inactive css classes on links and nodes
        //   - opening path of collapsibles in DOM tree under the root node

        // Setting css classes

        const setState = (e) => {
            if (dataIdOf(e) === id) {
                $(e).removeClass('-inactive');
                $(e).addClass('-active');
            } else {
                $(e).removeClass('-active');
                $(e).addClass('-inactive');
            }
        };

        this.linkTargets.forEach((link) => setState(link));
        this.nodeTargets.forEach((link) => setState(link));

        // Gather knowledge about the desired collapsible states

        const $collapsiblesInScope = $(this.element).find('[data-controller="collapsible"]');
        const visibleMap = new Map<object, boolean>();

        const uncover = (node) => {
            const $closest = $(node).closest('[data-controller="collapsible"]');

            if (!$closest.length) {
                return;
            }

            // for the map we need the exact reference from $collapsiblesInScope
            const $reference = $collapsiblesInScope.filter((index, e) => $(e).is($closest));

            if ($reference.length) {
                visibleMap.set($reference[0], true);

                uncover($reference.parent());
            }
        };

        this.linkTargets.filter(link => dataIdOf(link) === id)
                        .forEach(link => uncover(link));

        this.nodeTargets.filter(node => dataIdOf(node) === id)
                        .forEach(node => uncover(node));

        // Set desired collapsible states

        $collapsiblesInScope.each((index, node) => {
            if(visibleMap.has(node) && visibleMap.get(node)) {
                (node as any).collapsible.show();
            } else {
                (node as any).collapsible.hide();
            }
        });
    }
}

import { Controller } from '@hotwired/stimulus'
import $ from 'jquery';

export default class extends Controller {
    static targets = [];
    static values = { visible: Boolean };

    connected = false;
    visible = false;

    initialize() {
        this.$element = null;

        this.visible = !!this.visibleValue;
    }

    connect() {
        this.connected = true;
        this.element.style.visibility = 'visible';
        this.$element = $(this.element);

        // attach controller to element;
        this.element[this.identifier] = this;

        if (this.visible) {
            this.show();
        } else {
            this.hide();
        }
    }

    disconnect() {
        this.connected = false;
        this.$element = null;

        // detach controller from element;
        this.element[this.identifier] = null;
    }

    show() {
        if (!this.connected) {
            return;
        }

        this.visible = true;
        this.$element.show();
    }

    hide() {
        if (!this.connected) {
            return;
        }

        this.visible = false;
        this.$element.hide();
    }
}

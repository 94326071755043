import { Controller } from '@hotwired/stimulus'
import * as $ from 'jquery';

export default class extends Controller {
    static targets = ['loadingElement'];

    declare readonly hasLoadingElementTarget: boolean
    declare readonly loadingElementTarget: Element
    declare readonly loadingElementTargets: Element[]

    showLoadState() {
        $(this.loadingElementTarget).show();
    }

    hideLoadState() {
        $(this.loadingElementTarget).hide();
    }
}

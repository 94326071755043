import { Controller } from '@hotwired/stimulus'
import $ from 'jquery';

export default class extends Controller {
    initialize() {
        this.$element = null;
    }

    connect() {
        this.$element = $(this.element);
        this.$element.on('click', () => {
            this.submit();
        });
    }

    submit() {
        this.$element.closest('form').submit();
    }

    disconnect() {
        this.$element.off('click');
        this.$element = null;
    }
}
